import React from 'react';
import loadable from '@loadable/component';
import { graphql } from 'gatsby';

import { Layout } from '@components/layout';

const PostarPlantao = loadable(() => import('@screens/postar_plantao/PostarPlantao'));

function Index(props) {
  return (
    <Layout {...props}>
      <PostarPlantao {...props} />
    </Layout>
  );
}

export const postarPlantaoQuery = graphql`
  {
    imageSharp {
      fixed(base64Width: 200) {
        src
        originalName
        base64
      }
    }
  }
`;

export default Index;
